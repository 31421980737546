import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const style = (theme: Theme) =>
	createStyles({
		root: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column'
		},
		appName: { fontSize: 16 },
		search: {
			borderRadius: 16
		},
		appGrid: {
			marginTop: theme.spacing(2)
		},
		appTile: {
			background: 'white',
			border: '1px solid rgba(50, 197, 255, 0.55)',
			borderRadius: '4px',
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
			width: 187,
			height: 187,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			'& > img': {
				width: 50,
				marginBottom: theme.spacing(3)
			},
			'& > h6': {
				textTransform: 'uppercase',
				letterSpacing: 1.43
			}
		},
		breadCrumbs: {
			display: 'inline-box',
			position: 'relative',
			background: '#fff',
			color: 'black',
			padding: theme.spacing(1, 3),
			border: ' 1px solid rgba(4, 109, 57, 0.3)',
			borderRadius: 22,
			width: 337,
			height: 44,
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1)
		},
		breadCrumbItem: {
			display: 'flex',
			alignItems: 'center',
			'& > *': {
				zIndex: 1,
				marginRight: theme.spacing(1)
			},
			'& > p': {
				marginRight: theme.spacing(0),
				fontWeight: 600
			}
		}
	});

export default style;
