import * as React from 'react';
import { WithStyles, Box, Grid, Typography, WithWidthProps, withWidth } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
// import md5 from 'md5';
import { Skeleton } from '@material-ui/lab';
import orderBy from 'lodash/orderBy';
// import Tour from '../../../components/Tour';
import { RefreshToken } from '../../../utils/events';
import withUser, { WithUserProps } from '../../../components/Login/consumer';
import { ChangeTitle } from '../../../redux/Root/actions';
import Style from './style';
import { RootState } from '../../../store';
import ApplicationTile from '../../../components/ApplicationTile';
import { generateStyle } from '../../../utils/functions';
import { GET_APPS, ApplicationsState } from '../../../redux/Home/types';
import { ActionStatus } from '../../../constants/api';

interface Props
	extends WithStyles<typeof Style>,
		RouteComponentProps,
		WithUserProps,
		WithWidthProps {
	// pass

	changeTitle: typeof ChangeTitle;
	applications: ApplicationsState;
	getApps: typeof GET_APPS.action;
}
// interface State {
// 	openTutorial: boolean;
// }
const tourStep = [
	{
		selector: '#headerMenuIcon',
		title: 'Profile',
		content: 'Quick Menu',
		padding: 0,
		anchor: 'top-right',
		arrow: 'right'
	},
	{
		selector: '#headerProfileIcon',
		title: 'Profile',
		content: 'Profile section allows you to logout and manage your profile.',
		padding: 0,
		anchor: 'top-left',
		arrow: 'left'
	},
	{
		selector: '#weather',
		title: 'Weather',
		content:
			'Personalized weather forecast keeps you upto date with weather conditions for your planning.',
		anchor: 'top-left',
		arrow: 'left'
	},
	{
		selector: '#searchBar',
		title: 'Search',
		showArrow: false,
		content: 'You may quickly want to search for any application by its name and operations.',
		padding: 0
	},
	{
		selector: '#applications',
		showArrow: false,
		title: 'Applicable Apps',
		content: 'All your applications to ease your work appear here.',
		padding: 0
	},
	{
		selector: '#messageBoard',
		title: 'Message Board',
		content: 'Message board guides you on important corporate bulletin.',
		anchor: 'bottom-left',
		arrow: 'left',
		padding: 0
	}
];
/**
 *
 */
export class Dashboard extends React.Component<Props> {
	/**
	 * Constructor
	 * @param {IMenuProps} props - Properties.
	 * @returns {void} Contructor.
	 */
	constructor(props: Props) {
		super(props);
		// this.state = {
		// 	openTutorial: false
		// };
	}

	componentDidMount = () => {
		document.title = 'MeraDMart';
		RefreshToken();
		const { applications, getApps } = this.props;
		if (applications.getApps !== ActionStatus.SUCCESS) {
			getApps();
		}
	};

	renderBreadCrumb = () => {
		const { classes } = this.props;
		return (
			<Box className={classes.breadCrumbs}>
				<Box className={classes.breadCrumbItem}>
					<Home />
					<Typography variant="body1">All Apps</Typography>
				</Box>
			</Box>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	renderTile = () => {
		const { classes, width, applications } = this.props;
		const webApplications = applications.apps.filter((u) => {
			const formFactor = get(u, 'formFactor', []) || [];
			return formFactor.includes('DESKTOP');
		});
		let Apps: Array<React.ReactNode> = [];
		const filteredApp = orderBy(webApplications, 'applicationTitle', 'asc');

		if (applications.getApps === ActionStatus.LOADING) {
			Apps.push(
				<Grid item key={1}>
					<Skeleton width={180} height={180} variant="rect" />
				</Grid>
			);
			Apps.push(
				<Grid item key={3}>
					<Skeleton width={180} height={180} variant="rect" />
				</Grid>
			);
			Apps.push(
				<Grid item key={2}>
					<Skeleton width={180} height={180} variant="rect" />
				</Grid>
			);
		}
		if (applications.getApps === ActionStatus.SUCCESS) {
			Apps = [];
		}
		if (isEmpty(filteredApp) && applications.getApps === ActionStatus.SUCCESS) {
			return (
				<Box className={classes.appGrid}>
					<Typography variant="h5" align="center">
						You do not have access to any applications.
					</Typography>
				</Box>
			);
		}
		if (applications.getApps === ActionStatus.SUCCESS) {
			filteredApp.forEach((a) => {
				Apps.push(
					<ApplicationTile appId={a.applicationCode} key={a.applicationCode} info={a} />
				);
			});
		}
		return (
			<Grid container spacing={width === 'xs' ? 3 : 6} className={classes.appGrid}>
				{Apps}
			</Grid>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, user } = this.props;
		// const { openTutorial } = this.state;
		return (
			<Box className={classes.root}>
				{/* {openTutorial && (
					<Tour
						open={openTutorial}
						steps={tourStep}
						padding={10}
						onTourExit={() => {
							localStorage.setItem(md5(`${user.subject}-tutorial-seen`), 'true');
							this.setState({ openTutorial: false });
						}}
					/>
				)} */}
				{this.renderBreadCrumb()}
				{this.renderTile()}
			</Box>
		);
	}
}

/**
 * Map state to props
 * @param {any} state Global state Object
 * @returns {Object} State to pros.
 */
const mapStateToProps = (state: RootState) => ({
	applications: state.applications
});

const mapDispatchToProps = {
	changeTitle: ChangeTitle,
	getApps: GET_APPS.action
};

export default generateStyle(
	Style,
	'MDFDashboard'
)(withWidth()(withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(Dashboard)))));
