import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {},
		appName: { fontSize: 16 },
		appTile: {
			backgroundColor: '#FFFFFF',
			border: '0.65px solid #0E7590',
			borderRadius: '12px',
			boxShadow: ' 0 2px 4px 0 rgba(0, 0, 0, 0.51), -2px 2px 4px 0 rgba(0, 0, 0, 0.36)',
			width: 180,
			height: 180,
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
			justifyContent: 'center',
			alignItems: 'center',
			'& > img': {
				width: 50,
				objectFit: 'contain',
				height: 50,
				marginBottom: theme.spacing(3)
			},
			'& > h6': {
				textTransform: 'uppercase',
				fontSize: 16,
				textAlign: 'center',
				width: 114,
				letterSpacing: 1.43
			},
			[theme.breakpoints.down('xs')]: {
				width: 150,
				height: 150
			}
		},
		backdrop: {
			pointerEvents: 'none',
			zIndex: 1000,
			position: 'absolute',
			top: '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			left: '50%',
			transform: 'translate(-50%,-50%)',
			background: 'rgba(255,255,255,0.5)',
			width: '100%',
			height: '100%'
		}
	});

export default Styles;
