import * as React from 'react';
import {
	WithStyles,
	Grid,
	ButtonBase,
	Typography,
	WithWidthProps,
	withWidth
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Style from './style';
import { ApplicationInfo } from '../../spa/types';
import { generateStyle } from '../../utils/functions';
import GetFileById from '../GetFileById';

export interface Props extends WithStyles<typeof Style>, WithWidthProps {
	//
	appId: string;
	info: ApplicationInfo;
}
/**
 *
 */
class ApplicationTile extends React.PureComponent<Props> {
	onSuccess = (url, type) => {
		return <img src={url} alt="icon" />;
	};

	onError = (url, type) => {
		return <img src="/static/images/defaultLogo.png" alt="icon" />;
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, info, width } = this.props;
		const name = get(info, 'applicationName', '');
		const applicationCode = get(info, 'applicationCode', '');
		const icon = get(info, 'applicationIcon', '');
		return (
			<Grid item>
				<ButtonBase
					className={classes.appTile}
					to={`/app/${applicationCode}`}
					component={Link}
					disabled={width === 'xs'}
				>
					<GetFileById url={icon} onSuccess={this.onSuccess} onError={this.onError} />
					<Typography align="center" variant="subtitle2" className={classes.appName}>
						{name}
					</Typography>
				</ButtonBase>
			</Grid>
		);
	}
}
export default generateStyle(Style, 'ApplicationTile')(withWidth()(ApplicationTile));
