import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Style from './style';
import { FileState, GET_FILE } from '../../redux/File/types';
import { RootState } from '../../reducers';
import { ActionState } from '../../constants/api';

export interface Props extends WithStyles<typeof Style> {
	//
	onSuccess?: (url: string, type?: string) => React.ReactNode;
	onError?: (url: string, type?: string) => React.ReactNode;
	file: FileState;
	getFile: typeof GET_FILE.action;
	url: string;
}
/**
 *
 */
class GetFileById extends React.PureComponent<Props> {
	id = nanoid(8);

	componentDidMount() {
		const { url, getFile } = this.props;
		if (!isEmpty(url)) {
			getFile({ id: this.id, url });
		}
	}

	renderSuccess = () => {
		const { onSuccess, file } = this.props;
		const getInfo = get(file.getFiles, this.id, {});
		const filePath = get(getInfo, 'url', '');
		const blob = get(getInfo, 'blob', '');
		if (onSuccess) {
			return onSuccess(filePath, blob);
		}
		return null;
	};

	renderError = () => {
		const { onError, file } = this.props;
		const getInfo = get(file.getFiles, this.id, {});
		const filePath = get(getInfo, 'url', '');
		const blob = get(getInfo, 'blob', '');
		if (onError) {
			return onError(filePath, blob);
		}
		return null;
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { file, url } = this.props;
		const getInfo = get(file.getFiles, this.id, {});
		const getFile = get(getInfo, 'getFile', '');
		return (
			<>
				{getFile === ActionState.SUCCESS && this.renderSuccess()}
				{(getFile !== ActionState.SUCCESS || isEmpty(url)) && this.renderError()}
			</>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	file: state.file
});
const mapDispatchToProps = {
	getFile: GET_FILE.action
};
export default withStyles(Style, { name: 'GetFileById' })(
	connect(mapStateToProps, mapDispatchToProps)(GetFileById)
);
